import React, { useState, useEffect } from "react";
import axios from "axios";
import AvaliacaoBotoes from "../AvaliacaoBotoes/AvaliacaoBotoes.js";
import RadioButtons from "../AvaliacaoBotoes/AvaliacaoProdutos.js";
import LoadingBar from "../Loading/LoadingBar.js";
import Pergunta14e15 from "./Pergunta14e15.js";
import Sucess from "../status/Sucess.js";
import Loading from "../Loading/Loading.js";
import APILoading from "../Loading/APILoading.js";
import ErroAPIDados from "../status/ErroAPIDados.js";
import If from "./If.js";
import { useParams } from "react-router-dom";


function Pergunta() {
    const [perguntas, setPerguntas] = useState([]);
    const [perguntaAtual, setPerguntaAtual] = useState(0);
    const [respostaSelecionada, setRespostaSelecionada] = useState(null);
    const [respostas, setRespostas] = useState([]);
    const [isSucessoVisible, setSucessoVisible] = useState(false);
    const [opacity, setOpacity] = useState("opacity-100");
    const [isRender, setIsRender] = useState(true);
    const [surveyid, setSurveyid] = useState(null)
    const [error, setError] = useState(false); // Estado para rastrear erros
    const [loading, setLoading] = useState(true);
    const [loadingAPI, setLoadingAPI] = useState(false)
    const [erroAPI, setErrorAPI] = useState(false)
    const [getErrorAPI, setgetErrorAPI] = useState("")

    const apiUrl = process.env.REACT_APP_API;

    // console.log(apiUrl, "API");
    let { chave } = useParams();

    useEffect(() => {
        // Use a variável "chave" em sua solicitação Axios
        axios
            .get(`${apiUrl}/survey/${chave}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "ooooooo",
                },
            })
            .then((response) => {
                setPerguntas(response.data);
                setSurveyid(response.data[0].surveyid);
                setLoading(false)
            })
            .catch((error) => {
                setError(true); // Se houver um erro na solicitação, armazene-o no estado de erro
                setLoading(false)

            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chave]); // Certifique-se de incluir "chave" como dependência


    const handleResposta = (valor, textareaValue) => {
        const perguntaAtualId = perguntas[perguntaAtual].perguntaid;

        // Verificar se já existe uma resposta para a pergunta atual
        if (respostas.some((resposta) => resposta.perguntaid === perguntaAtualId)) {
            return;
        }

        const respostaAtual = {
            surveyid: surveyid,
            chave: chave,
            perguntaid: perguntaAtualId,
            resposta_number: valor,
            resposta_text: textareaValue,
        };

        const respostasAtualizadas = [...respostas];
        respostasAtualizadas.push(respostaAtual);
        setRespostas(respostasAtualizadas);

        // console.log("Respostas Atuais:", respostasAtualizadas);

        setOpacity("opacity-25");

        if (respostasAtualizadas.length === 16) {
            setLoadingAPI(true)
            // console.log(respostasAtualizadas, 'respotas atualizadas api')
            axios
                .post(`${apiUrl}/survey/update`, respostasAtualizadas, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "ooooooo",
                    },
                })
                .then((response) => {
                    // console.log('Entrou na api e enviou a resposta')
                    // console.log(response.data);
                    setgetErrorAPI(response.data[0].mensagem);
                    setLoadingAPI(false)
                    setSucessoVisible(true);
                })
                .catch((error) => {
                    console.log(error.message);
                    setLoadingAPI(false)
                    setErrorAPI(true)
                    setgetErrorAPI(error.message[0].mensagem)
                });
        }

        if (perguntaAtual === perguntas.length - 1) {

        } else {
            if (perguntaAtual < perguntas.length - 1) {
                setTimeout(() => {
                    setPerguntaAtual(perguntaAtual + 1);
                    setRespostaSelecionada(null);
                    setOpacity("opacity-100");
                    setIsRender(false);
                }, 700);
                setTimeout(() => {
                    setIsRender(true);
                }, 720);
            }
        }
    };

    return (
        <>
            {loading ? (
                <div className={`box-perguntas mx-auto mt-10 p-4 h-80`}>
                    <h1 className="text-message">Carregando Pesquisa.</h1>
                    <Loading />
                </div>
            ) : error ? (
                <div className={`box-perguntas mx-auto mt-10 p-4 h-72`}>
                    <h1 className="text-message">Pesquisa Não Encontrada.</h1>
                </div>
            ) : loadingAPI ? (
                <div className={`box-perguntas h-max mx-auto mt-10 p-4 `}>
                    <h1 className="text-message">Aguarde...</h1>
                    <APILoading />
                </div>
            ) : erroAPI ? (
                <div className={`box-perguntas h-max mx-auto mt-10 p-4 `}>
                    <h1 className="text-message">Erro ao enviar dados, tente novamente mais tarde.</h1>
                    <ErroAPIDados />
                    <p>Erro: ${getErrorAPI}</p>
                </div>
            ) : isSucessoVisible ? (
                <div className={`box-perguntas h-max mx-auto mt-10 p-4 `}>
                    <h1 className="text-message">Pesquisa Completa!</h1>
                    <Sucess />
                </div>
            ) : perguntaAtual < perguntas.length && (
                <div className={`bg-white border-2 border-border w-4/5 h-max mx-auto mt-3 p-4 ${opacity}`}>
                    <h1 className="text-xl ml-8 md:ml-0 text-center">

                        <b>{perguntaAtual + 1})</b><span> </span><span dangerouslySetInnerHTML={{ __html: perguntas[perguntaAtual].pergunta }} />
                    </h1>
                    <div className="mt-16 mb-10 md:mt-3 md:mb-5">
                        {perguntaAtual === 14 ? (
                            <Pergunta14e15 onRespostaSelecionada={handleResposta} perguntaAtual={perguntaAtual} />
                        ) : perguntaAtual === 15 ? (
                            <Pergunta14e15 onRespostaSelecionada={handleResposta} perguntaAtual={perguntaAtual} />
                        ) : (
                            perguntaAtual < 5 ? (
                                <If test={isRender}>
                                    <AvaliacaoBotoes respostaSelecionada={respostaSelecionada} onRespostaSelecionada={handleResposta} />
                                </If>
                            ) : (
                                <RadioButtons respostaSelecionada={respostaSelecionada} onRespostaSelecionada={handleResposta} />
                            )
                        )}
                    </div>
                    <div className="flex items-center justify-center w-4/12 md:w-7/12 xxs:w-6/12 mx-auto mt-4">
                        <LoadingBar progress={perguntaAtual + 1} />
                    </div>
                </div>
            )}
        </>
    );
}

export default Pergunta;
