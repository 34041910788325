// Rotas.js

import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import NaoEncontrado from '../status/NaoEncontrado.js'
import Pergunta from '../Perguntas/Perguntas';
function Rotas() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<NaoEncontrado />} />
                <Route path="/:chave" element={<Pergunta />} /> {/* Use /pergunta/:chave para capturar a chave da URL */}
            </Routes>
        </BrowserRouter>
    );
}

export default Rotas;
