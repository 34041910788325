import React, { useState, useEffect } from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


function AvaliacaoBotoes({ onRespostaSelecionada }) {
    const [valorClicado, setValorClicado] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [checkedBox, setCheckedBox] = useState(null);


    const handleClick = (valor) => {
        setValorClicado(valor);
        onRespostaSelecionada(valor);
        setCheckedBox(valor);
    }
    // console.log(valorClicado, 'valor clicado')
    if (valorClicado != null) {
        // console.log(valorClicado, 'valor clicado')
        setTimeout(() => {
            setValorClicado(null);
            setCheckedBox(null);
        }, 700); // Aguarde 1 segundo (você pode ajustar esse valor)
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const checkbox = -1
    return (
        <>
            <div className="w-3/5 mx-auto text-center">
                <p className="italic text-gray-500">0 - Muito Insatisfeito</p>
                <p className="italic text-gray-500">10 - Muito Satisfeito</p>
            </div>
            <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} items-center justify-center`}>
                <div className="mt-2">
                    <button
                        className={`button-regua  button-red ${valorClicado === 0 ? 'btn-red-click' : ''}`}
                        onClick={() => handleClick(0)}
                    >
                        0
                    </button>
                    <button
                        className={`button-regua button-red ${valorClicado === 1 ? 'btn-red-click' : ''}`}
                        onClick={() => handleClick(1)}
                    >
                        1
                    </button>
                    <button
                        className={`button-regua button-red ${valorClicado === 2 ? 'btn-red-click' : ''}`}
                        onClick={() => handleClick(2)}
                    >
                        2
                    </button>
                    <button
                        className={`button-regua button-red ${valorClicado === 3 ? 'btn-red-click' : ''}`}
                        onClick={() => handleClick(3)}
                    >
                        3
                    </button>
                    <button
                        className={`button-regua button-red ${valorClicado === 4 ? 'btn-red-click' : ''}`}
                        onClick={() => handleClick(4)}
                    >
                        4
                    </button>
                    <button
                        className={`button-regua button-red ${valorClicado === 5 ? 'btn-red-click' : ''}`}
                        onClick={() => handleClick(5)}
                    >
                        5
                    </button>
                </div>
                <div className={`mt-2 ${isMobile ? 'mt-4' : ''}`}>
                    <button
                        className={`button-regua button-red ${valorClicado === 6 ? 'btn-red-click' : ''}`}
                        onClick={() => handleClick(6)}
                    >
                        6
                    </button>
                    <button
                        className={`button-regua button-yellow ${valorClicado === 7 ? 'btn-yellow-click' : ''}`}
                        onClick={() => handleClick(7)}
                    >
                        7
                    </button>
                    <button
                        className={`button-regua button-yellow ${valorClicado === 8 ? 'btn-yellow-click' : ''}`}
                        onClick={() => handleClick(8)}
                    >
                        8
                    </button>
                    <button
                        className={`button-regua button-green ${valorClicado === 9 ? 'btn-green-click' : ''}`}
                        onClick={() => handleClick(9)}
                    >
                        9
                    </button>
                    <button
                        className={`button-regua button-green ${valorClicado === 10 ? 'btn-green-click' : ''}`}
                        onClick={() => handleClick(10)}
                    >
                        10
                    </button>
                </div>
            </div>
            <FormGroup>
                <div className={`flex w-12/12 items-center justify-center mt-10 mx-auto md:mt-2`}>
                    <FormControlLabel control={<Checkbox />} onClick={() => handleClick(checkbox)} checked={checkedBox === -1} label="Não consigo avaliar" />
                </div>
            </FormGroup>
        </>
    );
}

export default AvaliacaoBotoes;
