import React from "react";

function LoadingBar(props) {
    const { progress } = props;

    // Garante que o progresso esteja dentro do intervalo de 1 a 16
    const clampedProgress = Math.min(Math.max(progress, 1), 16);

    // Calcula a largura da barra de progresso com base no valor de progress
    const progressBarWidth = (clampedProgress / 16) * 100 + "%";

    return (
        <div className=" w-full p-2 xxs:p-0">
            <div className="relative h-3 bg-gray-400 rounded-full">
                <div
                    className="absolute left-0 top-0 h-full bg-green-400 rounded-full"
                    style={{ width: progressBarWidth }}
                ></div>
            </div>
            <p className="text-center mt-2 whitespace-nowrap">
                Pergunta {progress} de 16
            </p>
        </div>
    );
}

export default LoadingBar;
