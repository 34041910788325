// App.js

import './App.css';
import Header from './components/Header/Header';
import Rotas from './components/Rotas/Rotas';

function App() {
  return (
    <>
      <div className="bg-primarybg w-full min-h-screen">
        <Header />
        <Rotas /> {/* Use o componente Rotas para gerenciar as rotas */}
      </div>
    </>
  )
}

export default App;
