import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
    value: PropTypes.any,
};

export default function UseRadioGroup({ onRespostaSelecionada }) {
    const [showTextarea, setShowTextarea] = useState(false);
    const [textareaValue, setTextareaValue] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [valorClicado, setValorClicado] = useState(null);
    const [checkedRadio, setCheckedRadio] = useState(null);
    const [borderColor, setBorderColor] = useState('border-gray-300');

    const handleRadioChange = (event) => {
        if (event.target.value === '4') {
            setShowTextarea(true);
        } else {
            setShowTextarea(false);
        }
    };

    const handleTextareaChange = (event) => {
        // Limita o Textarea a 600 characters
        if (event.target.value.length <= 600) {
            setTextareaValue(event.target.value);
            // Verifica se o textarea está vazio e define a cor da borda
            if (event.target.value.trim() === '') {
                setBorderColor('border-red-600');
            } else {
                setBorderColor('border-gray-300');
            }
        }
    };

    const handleClick = (valor) => {
        // Marque o novo valor clicado
        setValorClicado(valor);
        setCheckedRadio(valor);
        if (valor === 4) {
            setShowTextarea(true);
        } else {
            setShowTextarea(false);
            // Verifique se o textarea está vazio
            if (textareaValue.trim() === '') {
                setBorderColor('border-red-600');
            } else {
                setBorderColor('border-gray-300');
            }
        }
        if (valor !== 4) {
            onRespostaSelecionada(valor);
            setTimeout(() => {
                setValorClicado(null);
                setCheckedRadio(null);
            }, 700);
        }
    };


    return (
        <div className="flex flex-col items-center md:flex-row md:items-start md:justify-start md:ml-5">
            <RadioGroup name="use-radio-group" defaultValue="first" onChange={handleRadioChange}>
                <div className="mb-4 md:flex-col">
                    <MyFormControlLabel
                        checked={checkedRadio === 1}
                        className="md:mt-2"
                        value="1"
                        label="Já Trabalho."
                        onClick={() => handleClick(1)}
                        control={<Radio />}
                    />
                    <MyFormControlLabel
                        checked={checkedRadio === 2}
                        className="md:mt-2 pl-[181px] xs:pl-0"
                        value="2"
                        label="Não é do meu segmento."
                        onClick={() => handleClick(2)}
                        control={<Radio />}
                    />
                </div>
                <div className="md:ml-0 mb-4 md:flex-col">
                    <MyFormControlLabel
                        checked={checkedRadio === 3}
                        className="md:mt-2"
                        value="3"
                        label="Não conheço e gostaria de conhecer."
                        onClick={() => handleClick(3)}
                        control={<Radio />}
                    />
                    <MyFormControlLabel
                        checked={checkedRadio === 4}
                        className="md:mt-2"
                        value="4"
                        label="Conheço, porém, trabalho com outras marcas."
                        onClick={() => handleClick(4)}
                        control={<Radio />}
                    />
                </div>
                {showTextarea && (
                    <div className="mt-2 w-full md:flex-col">
                        <textarea
                            className={`p-2 border w-full h-32 rounded-md resize-none outline-none ${borderColor}`}
                            placeholder="Digite o motivo..."
                            value={textareaValue}
                            onChange={handleTextareaChange}
                        />
                        {borderColor === 'border-red-600' && (
                            <p className="text-red-600">Este campo é obrigatório.</p>
                        )}
                        <div className="text-right text-gray-500">
                            {textareaValue.length}/600
                        </div>
                        <div className='w-full flex items-end justify-end'>
                            <button
                                className='bg-blue-500 text-white right-0 py-2 px-4 rounded-sm'
                                onClick={() => {
                                    if (textareaValue.trim() !== '') {
                                        onRespostaSelecionada(4, textareaValue);
                                    } else {
                                        // Define a cor da borda como vermelha se o textarea estiver vazio
                                        setBorderColor('border-red-600');
                                    }
                                    // Limpar o estado após um tempo, apenas se a resposta não estiver vazia
                                    if (textareaValue.trim() !== '') {
                                        setTimeout(() => {
                                            setValorClicado(null);
                                            setCheckedRadio(null);
                                            setShowTextarea(false);
                                            setTextareaValue('');
                                        }, 700);
                                    }
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                )}
            </RadioGroup>
        </div>
    );
}
