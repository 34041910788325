import React, { useState } from "react";

function Pergunta14e15({ onRespostaSelecionada, perguntaAtual }) {
    const [textareaValue, setTextareaValue] = useState('');
    const [isTextareaEmpty, setIsTextareaEmpty] = useState(true);
    const [borderColor, setBorderColor] = useState('border-gray-300');

    console.log(perguntaAtual)

    const handleTextareaChange = (event) => {
        const newValue = event.target.value;

        // Verifica se o texto excede o limite de 600 caracteres
        if (newValue.length <= 600) {
            setTextareaValue(newValue);
            // Verifica se o textarea está vazio e define a cor da borda
            if (newValue.trim() === '') {
                setIsTextareaEmpty(true);
                setBorderColor('border-red-600');
            } else {
                setIsTextareaEmpty(false);
                setBorderColor('border-gray-300');
            }
        }
    }

    const handleOk = () => {
        if (textareaValue.trim() === '') {
            setBorderColor('border-red-600');
        } else {
            setBorderColor('border-gray-300');
            setTimeout(() => {
                onRespostaSelecionada(15, textareaValue); // Pergunta 14
                setTextareaValue('');
            }, 700);
        }

    };

    const handleEnviar = () => {
        if (textareaValue.trim() === '') {
            setBorderColor('border-red-600');
        } else {
            setBorderColor('border-gray-300');
            setTimeout(() => {
                onRespostaSelecionada(16, textareaValue); // Pergunta 15
                setTextareaValue('');
            }, 700);
        }

    };


    return (
        <>
            <div className={`mt-2 w-4/5 ml-10 md:ml-0 md:w-full ${isTextareaEmpty ? "border-red-500" : "border-gray-300"}`}>
                <textarea
                    className={`p-2 border w-full h-32 rounded-md  resize-none outline-none ${borderColor}`}
                    placeholder="Digite a sua resposta..."
                    value={textareaValue}
                    onChange={handleTextareaChange}
                />
                {borderColor === 'border-red-600' && (
                    <p className="text-red-600">Este campo é obrigatório.</p>
                )}
                <div className="text-right text-gray-500">
                    {textareaValue.length}/600
                </div>
            </div>

            <div className="w-4/5 ml-10 flex md:ml-0 md:w-full items-end justify-end mt-4">
                {perguntaAtual === 14 && (
                    <button
                        className={`bg-blue-500 text-white py-2 px-4 rounded-sm mr-2`}
                        onClick={handleOk}
                    >
                        Ok
                    </button>
                )}
                {perguntaAtual === 15 && (
                    <button
                        className={`bg-blue-500 text-white py-2 px-4 rounded-sm`}
                        onClick={handleEnviar}
                    >
                        Enviar
                    </button>
                )}
            </div>
        </>
    );
}

export default Pergunta14e15;
