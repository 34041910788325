import React from "react";
import logo from "../../img/logo-esplane.png";

function Header() {
    return (
        <header>
            <div className="w-4/5 mx-auto">
                <div className="text-center">
                    <div className="pt-4 pl-10 mx-auto fd:pl-0 md:pl-0">
                        <img className="w-48 h-16 md:mx-auto" src={logo} alt="Esplane" />
                    </div>
                    <h1 className="font-poppins text-3xl mt-4"><b>Pesquisa de Satisfação</b></h1>
                </div>
                {/* <h1 className="font-poppins text-3xl">Pesquisa de Satisfação</h1> */}
                {/* <p className="mt-2 w-5/12 text-sm mx-auto opacity-50 italic font-medium">
                    Gostaríamos que nos ajudasse a atendê-lo melhor. Para isso, poderia nos avaliar respondendo a pesquisa abaixo, por favor?
                </p>

                <p className="font-poppins text-1xl mt-4 font-normal">Cliente: <b>João</b></p>
                <p className="font-poppins text-1xl font-normal">Visita realizada em 22/09/2023 pelo Consultor <b>Michel</b></p> */}
            </div>
        </header>
    );
}

export default Header;
